<template>
  <div class="content">
    <div>
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <h2 class="welcome-babyshop">Welcome to Beet Loyality Promo.</h2>
      <h6>Verification for your account</h6>
      <div class="d-flex justify-content-between mt-3">
        <vue-country-code
          enableSearchField=true
          enabledCountryCode=true
          class="option-country-code"
          @onSelect="onSelect"
          :preferredCountries="['id', 'us', 'gb']">
        </vue-country-code>
        <input class="phone-number" type="text" placeholder="Phone Number">
      </div>
      <div class="text-muted desc-muted">
        You will receive an SMS verification that may apply message and data rates.
      </div>
    </div>
    <div>
      <div class="button">
        Register
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterOtp',
  mounted () {
    this.firstRender()
  },
  methods: {
    firstRender () {
      console.log('Hello World')
    },
    pushToLoginEmail () {
      this.$router.push({ name: 'LoginEmail' })
    },
    onSelect (event) {
      console.log('e', event)
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.back-button{
  margin: 20px 0;
}
.option-country-code{
  padding: 3px 5px;
}
input.phone-number{
  width: 100%;
  border-radius: 0 5px 5px 0;
  border: 1px solid #BBBBBB;
  margin-left: -2px;
  padding-left: 8px;
}
input.phone-number:focus, .option-country-code:focus{
  outline: 0.3px #6B4EFF solid;
}
.desc-muted{
  margin-top: 10px;
  font-size: .8rem;
}
.button{
  background-color: #6B4EFF;
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0;
}
.button-email{
  text-align: center;
  margin: 20px 0;
  color: #6B4EFF;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 495px) {
  .welcome-babyshop{
    width: 50%;
  }
}
</style>
