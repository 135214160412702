<template>
  <div class="content">
    <div class="d-flex align-items-center" style="width: 100%">
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <div style="width: 100%">
        <div class="text-scan">
          {{$t('my_qr')}}
        </div>
      </div>
    </div>
    <div class="wrapper-content">
      <div class="web-cam">
        <div class="wrapper-qr-code">
          <img src="@/assets/images/example-qrcode.png" alt="QR Code">
        </div>
        <h4 class="text-center">{{getDataCustomer.name}}</h4>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <div class="button light mr-2">{{$t('save')}}</div>
      <div class="button thick">{{$t('share')}}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MyQr',
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer'])
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100vh;
  padding: 10px 20px;
  border: 1px solid;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.text-scan{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrapper-content{
  height: 65vh;
}
.web-cam{
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-top: 0;
}
.wrapper-qr-code{
  width: 80%;
  height: 80%;
  margin: auto;
}
.wrapper-qr-code > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.button{
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.thick {
  color: white;
  background-color: #6B4EFF;
}
.light{
  background-color: #E7E7FF;
  color: #6B4EFF;
}
</style>
