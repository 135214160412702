<template>
  <div>
    <div v-if="stateModalCz" class="custom-modal">
      <div class="modal-box">
        <div class="d-flex justify-content-end header-custom-modal">
          <div class="wrapper-icon-close" @click="handleCloseModal">
            <img src="@/assets/images/icons8-macos-close-90.png" alt="Close">
          </div>
        </div>
        <iframe class="iframe-cz" :src="urlVendor" allow="geolocation" frameborder="0"></iframe>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <div class="d-flex align-items-center" style="width: 100%">
          <div class="back-button feedback" @click="goBack">
            <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
          </div>
          <div style="width: 100%">
            <div class="text-redeem">
              {{$t('top_up')}}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center  mx-4">
          <div class="wrapper-image">
            <img v-if="getDataCustomer.image" :src="getDataCustomer.image" alt="">
            <img  v-else src="@/assets/images/profile.jpg" alt="">
          </div>
          <div class="ml-3">
            <h3>{{getDataCustomer.name}}</h3>
            <h6>{{getDataCustomer.email}}</h6>
          </div>
        </div>
        <!-- <div class="line" /> -->
        <div class="d-flex justify-content-between align-items-center text-points">
          <div>{{$t('my_saldo')}} : Rp. {{getDataCustomer.saldo_amount | numFormat}}</div>
          <div />
        </div>
        <h5 class="mb-4">{{$t('choose_nominal')}}</h5>
        <div class="row">
          <div v-for="(data, index) in optionNominal" :key="index" class="col-lg-2 col-md-3 col-4 feedback" @click="setNominal(data)">
            <div class="card-nominal">
              Rp. {{data | numFormat}}
            </div>
          </div>
        </div>
        <div class="wrapper-input">
          <input type="number" :class="[nominal.length | filled ? 'filled' : '', 'manual-nominal' ]" :placeholder="$t('type_your_own_nominal')" v-model="nominal" />
          <div class="close feedback" v-if="nominal.length | filled" @click="setNominal('')">X</div>
        </div>

      </div>
      <div class="bottom">
        <button :disabled="filled === false && nominal.length < 1 ? true : false" :class="[nominal.length | filled ? 'next-filled' : '', 'next' ]" @click="confirm">
          {{$t('confirm')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import randomstring from 'randomstring'
import axios from 'axios'
import dayjs from 'dayjs'

export default {
  name: 'Topup',
  data () {
    return {
      optionNominal: [20000, 50000, 100000, 200000, 300000, 500000],
      nominal: '',
      filled: false,
      TRANSIDMERCHANT: randomstring.generate(12),
      urlVendor: '',
      stateModalCz: false,
      dataBusiness: {}
    }
  },
  methods: {
    ...mapActions(['handlePaymentMethodBusiness', 'handleDataCustomer']),
    goBack () {
      this.$router.go(-1)
    },
    setNominal (val) {
      this.nominal = val
      if (val) {
        this.filled = true
      } else {
        this.filled = false
      }
    },
    async confirm () {
      const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/business-noverify/1`)
      console.log('Data business lifetech', data.data)
      const dataBusiness = data.data
      this.dataBusiness = data.data
      this.stateModalCz = true
      try {
        const generateSignature = {
          data: {
            request: {
              vendorIdentifier: dataBusiness.cz_vendor_identifier,
              token: '',
              referenceId: this.TRANSIDMERCHANT,
              entityId: dataBusiness.cz_entity_id,
              merchantName: dataBusiness.name,
              merchantDescription: 'Cashlez Sunter',
              currencyCode: 'IDR',
              amount: parseInt(this.nominal),
              callbackSuccess: '',
              callbackFailure: '',
              message: '',
              description: 'Test Transaction',
              transactionUsername: dataBusiness.cz_user
            }
          },
          signature: ''
        }
        const resSignature = await axios.post('https://api.beetpos.com/api/v1/signature/generate', generateSignature)
        generateSignature.signature = resSignature.data.data[0].result
        console.log('generateSignature', generateSignature)
        const generateUrlVendor = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/signature/generate-url-vendor`, generateSignature)
        console.log('generateUrlVendor', generateUrlVendor.data.data)
        this.urlVendor = generateUrlVendor.data.data.response.generatedUrl
      } catch (error) {
        console.log(error)
      }
    },
    handleCloseModal () {
      this.stateModalCz = false
    },
    async checkStatusPaymentCz (url) {
      console.log('urlnya', url)
      const result = await axios.post('https://api-link.cashlez.com/validate_url', {
        status: '',
        message: '',
        data: {
          request: {
            generatedUrl: url
          }
        }
      })
      console.log('result.data.data', result.data.data)
      console.log('result.data.data.response.processStatus', result.data.data.response.processStatus)
      return result.data.data
    },
    async handleTransactionCz (params) {
      try {
        const paymentMethods = await this.handlePaymentMethodBusiness({ businessId: 1 })

        console.log('paymentMethods', paymentMethods)

        const customer_account_id = localStorage.getItem('customer_account_id')

        const receiptId = 'TOPUP_' +
                customer_account_id +
                ':' +
                dayjs(new Date()).format('YYYY/MM/DD:HH:mm:ss')
        let paymentMethod
        let paymentMethodId
        if (params.response.paymentType.id === 1) paymentMethod = 'ecomm'
        if (params.response.paymentType.id === 2) paymentMethod = 'virtual'
        if (params.response.paymentType.id === 3) paymentMethod = 'ovo'
        if (params.response.paymentType.id === 4) paymentMethod = 'qr'
        if (params.response.paymentType.id === 7) paymentMethod = 'virtual'

        paymentMethods.map(value => {
          if (value.cz_type === paymentMethod) {
            paymentMethodId = value.id
          }
        })

        const sendData = {
          receipt_id: receiptId,
          items: [],
          outlet_id: this.dataBusiness.Outlets[0].id,
          business_id: 1,
          customer_account_id,
          payment_method_id: paymentMethodId,
          payment_discount: 0,
          payment_tax: 0,
          payment_service: 0,
          payment_total: this.nominal,
          amount: this.nominal,
          payment_change: 0,
          invoice: this.TRANSIDMERCHANT,
          status: 'done',
          paymentchannel: params.response.paymentType.code === 'ECOMM' ? 15 : params.response.paymentType.code === 'TCASH_QR_PAYMENT' ? 0 : null
        }
        console.log('sendData', sendData)
        const resTransaction = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/transaction-customer`, sendData)
        console.log('response transaction', resTransaction.data.data)

        const dataTopup = {
          customer_account_id,
          status: 'onprogress',
          payment_method_id: paymentMethodId,
          payment_amount: this.nominal,
          saldo_amount: this.nominal,
          multi_currency: '',
          multi_currency_ratio: '',
          multi_currency_name: '',
          multi_currency_origin: '',
          multi_currency_used: '',
          multi_currency_total: ''
        }

        console.log('dataTopup', dataTopup)

        await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/customer-topup-history/top-up`, dataTopup)
        await this.handleDataCustomer({ id: customer_account_id })
        this.nominal = ''
        setTimeout(() => {
          this.stateModalCz = false
        }, 2000)
        this.$toasted.success('Success topup saldo', {
          theme: 'bubble',
          position: 'top-right',
          duration: 4000
        })
      } catch (error) {
        this.$toasted.error('Failed topup saldo', {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
        console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer', 'getCustomerPoints', 'getPaymentMethodBusiness'])
  },
  watch: {
    // nominal (val) {
    //   console.log('nominal', val)
    //   if(val !== 'Rp. ' || val !== 'Rp. NaN' || !val) {
    //     console.log('process number format')
    //     const intVal = val.replace(/\,/g,'').replace('Rp. ', '')
    //     const formatNumber = new Intl.NumberFormat().format(parseInt(intVal))
    //     this.nominal = `Rp. ${formatNumber}`
    //   } else {
    //     this.nominal = ''
    //     return
    //   }
    // }
    urlVendor (val) {
      const looping = setInterval(async () => {
        const data = await this.checkStatusPaymentCz(val)
        await this.checkStatusPaymentCz(val)
        if (!this.stateModalCz) {
          console.log('Event close modal')
          clearInterval(looping)
        }
        if (data.response.processStatus === 'APPROVED') {
          await this.handleTransactionCz(data)
          console.log('Data ketika Approved', data)
          clearInterval(looping)
        }
      }, 3000)
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content{
  height: 90vh;
  overflow-y: auto;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(../../assets/images/bg-prof3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.next{
  width: 100%;
  background-color: grey;
  padding: 10px 0;
  text-align: center;
  color: white;
  border-radius: 15px;
}
.next-filled{
  background-color: #6B4EFF;
}
.next-filled:hover{
  transform: scale(1.01);
}
button{
  border: none;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.wrapper-image{
  width: 80px;
  height: 80px;
}
.wrapper-image>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.text-redeem{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}

.text-points{
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
}
.card-nominal{
  margin-bottom: 20px;
  padding: 15px 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 5px 1px grey;
}
.manual-nominal{
  width: 100%;
  padding: 15px 0;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 5px 1px grey;
}
.card-nominal:hover, .manual-nominal:hover{
  transform: scale(1.01);
}
input{
  border: none;
}
input:focus{
  outline: none;
}
.filled{
  box-shadow: 0 0 5px 1px green;
}
.wrapper-input{
  position: relative;
}
.close{
  padding: 6px 9px;
  background-color: black;
  border-radius: 50%;
  color: white;
  font-size: 11px;
  font-weight: 400;
  position: absolute;
  right: 20px;
  top: 12px;
}
.custom-modal{
  top: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.modal-box{
  background-color: rgb(242,247,252);
  box-shadow: 0 0 3px 1px #6B4EFF;
  width: 99%;
}
.modal-box > iframe {
  width: 100%;
  height: 100%;
}
.header-custom-modal {
  background-color: rgb(242,247,252);
}
.wrapper-icon-close{
  margin: 10px 25px;
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.wrapper-icon-close>img{
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 495px) {
  .card-nominal, .manual-nominal{
    font-size: 11px;
  }
}
</style>
