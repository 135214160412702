<template>
  <div class="wrapper-card-login">
    <div class="form-auth">
      <div class="wrapper-content">
        <div class="content">
          <div v-if="withScan === 'YES'">
            <div class="wrapper-icon-back" @click="goBack">
              <img src="@/assets/images/back-arrow.png" alt="Back">
            </div>
          </div>
          <div class="title">Signup</div>
          <hr />
          <div class="form-email d-flex flex-column">
            <label for="email">Email</label>
            <input id="email" type="text" name="email" v-model="email" placeholder="please input your email">
          </div>
          <div class="form-password d-flex flex-column">
            <label for="password">Password</label>
            <input id="password" type="password" name="password" v-model="password" placeholder="please input your password">
          </div>
          <div class="form-phone-number d-flex flex-column mb-3">
            <label for="phone-number">Phone Number</label>
            <input id="phone-number" type="text" name="phone-number" v-model="phoneNumber" placeholder="please input your phone number">
          </div>
          <b-modal modal-footer id="modal-1" title="Please insert code verification">
            <div v-if="!statusWhatsapp" class="verify-code-title">Verify code : <span class="verify-code">{{dataCustomerSignup.verification_code}}</span></div>
            <div v-else class="check-whatsapp">
              <div>Check your whatsapp</div>
              <div v-if="messageNotSent">
                <div class="text-muted">
                  didn't receive the message? <span class="text-primary" style="cursor: pointer" @click="showVerifyCode = true">show here</span>
                </div>
                <div v-if="showVerifyCode">
                  {{verifyCode}}
                </div>
              </div>
            </div>
            <div class="mt-3">
              <input type="text" placeholder="please input verify code" v-model="inputVerify">
            </div>
            <template #modal-footer>
              <div v-if="email && password && phoneNumber">
                <div class="w-100">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="handleSignup"
                  >
                    Submit
                  </b-button>
                </div>
              </div>
              <div v-else>
                <div class="w-100">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    disabled
                  >
                    Submit
                  </b-button>
                </div>
              </div>
            </template>
          </b-modal>
          <div class="button disabled" v-if="email == '' || password == '' || phoneNumber == ''" >Signup</div>
          <div class="button" v-else v-b-modal.modal-1 @click="sendVerifyCode">Signup</div>
          <div class="to-login text-muted">already have an account? please <span @click="pushToLogin" class="signup">Login</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'RegisterWithScan.vue',
  data: () => {
    return {
      email: '',
      password: '',
      phoneNumber: '',
      inputVerify: '',
      dataCustomerSignup: {},
      statusWhatsapp: false,
      withScan: localStorage.getItem('with_scan'),
      verifyCode: '',
      messageNotSent: false,
      showVerifyCode: false
    }
  },
  methods: {
    async sendVerifyCode () {
      try {
        if (!this.email && !this.password && !this.phoneNumber) {
          Swal.fire('Try again!', 'form must be filled', 'warning')
        } else {
          const result = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/customer/register`, { email: this.email, password: this.password, phone_number: this.phoneNumber })
          this.dataCustomerSignup = result.data.data
          console.log('verify codenya: ', result.data.data.verification_code)
          const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/auth/guest/login`)

          this.verifyCode = result.data.data.verification_code
          await this.handleSendWhatsapp(result.data.data.verification_code)
        }
      } catch (error) {
        this.$toasted.show('Verify code not sent', {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
        this.statusWhatsapp = false
      }
    },
    async handleSendWhatsapp (verifyCode) {
      try {
        const tempSplit = this.phoneNumber.toString().split('')
        console.log('tempSplit', tempSplit)
        if (tempSplit[0] == 0 || tempSplit[0].length == 0) {
          tempSplit[0] = '62'
        } else if (tempSplit[0] == 8) {
          tempSplit.unshift(62)
        }
        const resultPhone = tempSplit.join('')
        console.log('resultPhone', resultPhone)
        const dataSend = {
          message: `
            Verify Code = ${verifyCode}\nPowered By Beetpos
          `,
          phone: resultPhone,
          device: 'backoffice_test3'
        }
        const sendMessage = await axios.post('https://thingproxy.freeboard.io/fetch/http://139.59.244.237:3001/api/v1/messaging/sendText', dataSend, {
          headers: {
            'x-api-key': 'EalYHzTieQVwZ83XnrPv'
          }
        })
        console.log('sendMessage', sendMessage)
        setTimeout(() => {
          this.messageNotSent = true
        }, 3000)
        this.$toasted.show('Verify code sent', {
          theme: 'bubble',
          position: 'top-right',
          duration: 5000
        })
        console.log('TIDAK masuk statement error')
        this.statusWhatsapp = true
      } catch (error) {
        this.statusWhatsapp = false
        console.log('masuk statement error')
        console.log('error', error)
        this.$toasted.show('Verify code not sent', {
          theme: 'bubble',
          position: 'top-right',
          duration: 3000
        })
      }
    },
    async handleSignup () {
      try {
        if (this.dataCustomerSignup.verification_code === this.inputVerify) {
          const result = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/customer/login`, { email: this.email, password: this.password })
          console.log('Waduhh', result.data.data)

          // for verification account
          await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/auth/verify-account-customer?customerId=${result.data.data.payload.customer_account_id}`, {
            headers: {
              Authorization: `Bearer ${result.data.data.token}}`
            }
          })

          localStorage.setItem('token', result.data.data.token)
          localStorage.setItem('customer_account_id', result.data.data.payload.customer_account_id)
          localStorage.setItem('with_scan', 'NO')
          this.$router.push({ name: 'MainAllbusiness' })
          Swal.fire('Register success', '', 'success')
        } else {
          Swal.fire('Verification failed', 'please try again', 'error')
        }
      } catch (error) {
        Swal.fire('Register failed', 'Something went wrong, please try again', 'error')
        console.log('error nya', error.response.data.status)
      }
    },
    pushToLogin () {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, I want to login'
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$router.push({ name: 'Login' })
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.wrapper-card-login{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-auth{
  width: 25%;
  box-shadow: 0 0 5px 4px #623863;
  border-radius: 10px;
  padding: 10px 20px;
  padding-bottom: 20px;
}

.content{
  position: relative;
}
.signup{
  text-decoration-line: underline;
  cursor: pointer;
  color: #623863;
}
.title{
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.form-email, .form-password{
  margin: 20px 0 ;
}
input{
  border: none;
  border-bottom: 1px solid #623863;
  padding: 5px 10px;
}
input:focus{
  outline: none;
  border-bottom: 4px solid #623863;
}
.button{
  width: 100%;
  padding: 4px 0;
  border-radius: 20px;
  background-color: #623863;
  color: white;
  text-align: center;
  cursor: pointer;
}
.to-login{
  margin-top: 20px;
  font-size: 15px;
}
.wrapper-icon-back{
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 10px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-bottom: 30px;
}
.wrapper-icon-back:hover{
  transform: scale(1.05);
}
.wrapper-icon-back > img{
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .form-auth{
    width: 65%;
  }
}
@media screen and (max-width: 495px) {
  .form-auth{
    width: 85%;
  }
  .title{
    font-size: 24px;
    font-weight: 600;
  }
  .form-email, .form-password, .form-phone-number, .button, .to-login{
    font-size: 14px;
  }
}
</style>
