<template>
  <div id="app">
    <div class="wrapper-content">
      <router-view/>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  methods: {
    ...mapActions(['interceptorRequest', 'interceptorResponse'])
  },
  created () {
    this.interceptorRequest()
    this.interceptorResponse()
  }
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #623863;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
body{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.click-feedback {
  cursor: pointer;
}
.click-feedback:hover {
  transform: scale(1.001);
}
</style>
