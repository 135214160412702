<template>
  <div class="content">
    <ModalInformation :title="'Success! Your Register is Successful'" :message="'Press okay to continue'" :buttonTitle="'Okay'" :show="showModalInformation" v-on:handleClose="handleClose"/>
    <div class="content-top">
      <div class="d-flex justify-content-between align-items-center">
        <div class="back-button feedback" @click="goBack">
          <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
        </div>
        <div class="change-number">Change Number</div>
      </div>
      <div class="d-flex flex-column align-items-center" style="height: 100%">
        <h4 class="my-2">Enter authentication code</h4>
        <div class="desc-4-digit">Enter the 4-digit that we have sent via the phone number <span class="font-weight-bold">+62 813-8172-5977</span></div>

        <div class="wrapper-input-verify mt-5">
          <input type="text" ref="id01" class="verify" id="id01" maxlength="1" v-model="id01">
          <input type="text" ref="id02" class="verify" id="id02" maxlength="1" v-model="id02">
          <input type="text" ref="id03" class="verify" id="id03" maxlength="1" v-model="id03">
          <input type="text" ref="id04" class="verify" id="id04" maxlength="1" v-model="id04">
          </div>
      </div>
    </div>
    <div>
      <div :class="[id01 && id02 && id03 && id04 ? 'active' : 'inactive', 'button']" @click="handleRegister">
        Continue
      </div>
      <div class="button-email">Resend Code</div>
    </div>
  </div>
</template>

<script>
import ModalInformation from '../../components/ModalInformation.vue'

export default {
  name: 'RegisterVerification',
  components: {
    ModalInformation
  },
  data: () => {
    return {
      id01: null,
      id02: null,
      id03: null,
      id04: null,
      showModalInformation: false
    }
  },
  mounted () {
    this.firstRender()
    console.log('refs', this.$refs.id03)
    this.$refs.id01.focus()
  },
  methods: {
    firstRender () {
      console.log('Hello World')
    },
    inputVerify (e) {
    },
    handleRegister () {
      if (this.id01 && this.id02 && this.id03 && this.id04) {
        this.showModalInformation = true
      }
    },
    handleClose () {
      this.showModalInformation = false
      this.$router.push({ path: '/main/home/category-feed/news' })
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  watch: {
    id01 (value) {
      if (value) {
        if (!this.id02) {
          this.$refs.id02.focus()
        } else if (!this.id03) {
          this.$refs.id03.focus()
        } else if (!this.id04) {
          this.$refs.id04.focus()
        } else {
          this.$refs.id04.focus()
        }
      }
    },
    id02 (value) {
      if (value) {
        if (!this.id03) {
          this.$refs.id03.focus()
        } else if (!this.id04) {
          this.$refs.id04.focus()
        } else {
          this.$refs.id04.focus()
        }
      } else {
        this.$refs.id01.focus()
      }
    },
    id03 (value) {
      if (value) {
        if (!this.id04) {
          this.$refs.id04.focus()
        } else {
          this.$refs.id04.focus()
        }
      } else {
        this.$refs.id02.focus()
      }
    },
    id04 (value) {
      if (value) {
        // do nothing
      } else {
        this.$refs.id03.focus()
      }
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.back-button{
  margin: 20px 0;
}
.option-country-code{
  padding: 3px 5px;
}
input.phone-number{
  width: 100%;
  border-radius: 0 5px 5px 0;
  border: 1px solid #BBBBBB;
  margin-left: -2px;
  padding-left: 8px;
}
input.phone-number:focus, .option-country-code:focus{
  outline: 0.3px #6B4EFF solid;
}
.desc-muted{
  margin-top: 10px;
  font-size: .8rem;
}
.button{
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.button.active{
  background-color: #6B4EFF;
  color: white;
}
.button.inactive{
  background-color: #E3E5E6;
  color: #979C9E;
}
.button-email{
  text-align: center;
  margin: 20px 0;
  color: #6B4EFF;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.change-number{
  color: #6B4EFF;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 15px
}
.desc-4-digit{
  text-align: center;
}
.wrapper-input-verify{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.verify{
  text-align: center;
  border: 0.3px #d5d6d6 solid;
  font-weight: 500;
}
input.verify:focus{
  outline: 0.3px #6B4EFF solid;
}
@media screen and (max-width: 768px) {
  .verify{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}
@media screen and (max-width: 495px) {
  .verify{
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
}
.content-top{
  height: 50%;
}
</style>
