<template>
  <div class="content">
    <div class="d-flex align-items-center" style="width: 100%">
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <div style="width: 100%">
        <div class="text-membership">
          {{$t('membership')}}
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center  mx-4">
      <div class="wrapper-image">
        <img v-if="getDataCustomer.image" :src="getDataCustomer.image" alt="">
        <img v-else src="@/assets/images/profile.jpg" alt="">
      </div>
      <div class="ml-3">
        <h3>{{getDataCustomer.name}}</h3>
        <h6>{{getDataCustomer.email}}</h6>
      </div>
    </div>
    <div class="line" />
    <div class="container-image-loyality">
      <div class="wrapper-image-loyality">
        <img src="@/assets/images/Ellipse 7.png" alt="Platinum">
      </div>
      <div class="text-points">{{$t('my_point')}} : {{getCustomerPoints}}</div>
    </div>
    <!-- <progress-bar
      :options="options"
      :value="value"
    /> -->
    <div class="progress mt-3">
      <div class="progress-bar" role="progressbar" :style="widthValue" :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="text-center mt-3">3000 {{$t('point_to')}} Emerald</div>
    <div class="font-weight-bold mt-1 mb-2">{{$t('expired_point')}}</div>
    <div :class="[showMenuPoints ? 'open' : '', 'select-points']">
      <div class="button-show-points" @click="openPoints"/>
      <div class="list-point" v-for="data in dataPoints" :key="data.id">
        {{data.point}} {{$t('point_will_expired')}} {{data.expired}}
      </div>
      <!-- <div class="list-point">
        2000 point will expired on 10 jan 2022
      </div>
      <div class="list-point">
        2000 point will expired on 10 jan 2022
      </div>
      <div class="list-point">
        2000 point will expired on 10 jan 2022
      </div> -->
    </div>
    <div class="font-weight-bold mt-1 mb-2">Latest Transaction</div>
    <div :class="[showMenuTransactions ? 'open' : '', 'select-points']">
      <div class="button-show-transactions" @click="openTransactions"/>
      <div class="list-point" v-for="data in dataLatestTransaction" :key="data.id">
        {{$t('you_earned')}} {{data.poin}} {{$t('point_on')}} {{data.date}}
      </div>
      <!-- <div class="list-point">
        You earned 500 point on 23 Oct 2021
      </div>
      <div class="list-point">
        You earned 200 point on 14 Oct 2021
      </div>
      <div class="list-point">
        You earned 300 point on 08 Oct 2021
      </div> -->
    </div>
    <div class="redeem-now" @click="pushTo('Redeem')">Redeem Now</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Membership',
  data () {
    return {
      showMenuTransactions: false,
      showMenuPoints: false,
      value: 65,
      widthValue: 'width: 65%',
      dataPoints: [
        {
          id: 1,
          point: 2000,
          expired: '10 Jan 2022'
        },
        {
          id: 2,
          point: 5000,
          expired: '20 Jan 2022'
        },
        {
          id: 3,
          point: 2000,
          expired: '28 jan 2022'
        }
      ],
      dataLatestTransaction: [
        {
          id: 1,
          point: 500,
          date: '23 Oct 2021'
        },
        {
          id: 2,
          point: 200,
          date: '14 Oct 2021'
        },
        {
          id: 3,
          point: 300,
          date: '08 Oct 2021'
        },
        {
          id: 4,
          point: 200,
          date: '14 Oct 2021'
        },
        {
          id: 5,
          point: 300,
          date: '08 Oct 2021'
        }
      ]
    }
  },
  methods: {
    pushTo (routeName) {
      this.$router.push({ name: routeName })
    },
    goBack () {
      this.$router.go(-1)
    },
    openPoints () {
      this.showMenuPoints = !this.showMenuPoints
    },
    openTransactions () {
      this.showMenuTransactions = !this.showMenuTransactions
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer', 'getCustomerPoints'])
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

h3, h6{
  padding: 0;
  margin: 0;
}
.content{
  height: 90vh;
  overflow-y: auto;
  padding: 10px 20px;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.text-membership{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrapper-image{
  width: 80px;
  height: 80px;
}
.wrapper-image>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.line{
  width: 100%;
  height: 12px;
  background-color: #E7E7FF;
  border-radius: 10px;
  margin: 10px 0;
}
.container-image-loyality{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wrapper-image-loyality{
  width: 130px;
  height: 130px;
}
.wrapper-image-loyality > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .vuejs-progress-bar{
  width: 100% !important;
}
.vuejs-progress-bar #line-progress{
  width: 100% !important;
} */
.progress{
  height: 4px;
}
.progress-bar{
  background-color: #6B4EFF;
}
.select-points{
  position: relative;
  /* padding: 10px 0; */
  border-radius: 7px;
  border: 1px solid #6B4EFF;
  height: 45px;
  overflow: auto;
  transition: .2s;
}
.button-show-points{
  right: 10px;
  top: 10px;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: black;
}
.button-show-transactions{
  right: 10px;
  top: 10px;
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: black;
}
.open{
  /* overflow-y: auto; */
  height: fit-content;
  transition: .5s;
}
.list-point{
  padding: 8px 20px;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.redeem-now{
  margin-top: 20px;
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #6B4EFF;
  color: white;
  font-weight: 600;
}
.text-points{
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
}
</style>
