<template>
  <div class="content">
    <div class="d-flex align-items-center" style="width: 100%">
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <div style="width: 100%">
        <div class="text-scan">
          {{$t('scan_qr')}}
        </div>
      </div>
    </div>
    <div class="wrapper-content mt-3">
      <div class="web-cam">
        <qrcode-stream @decode="onDecode"></qrcode-stream>
      </div>
    </div>
    <div class="button mt-4" @click="pushTo('MyQr')">{{$t('show_my_qr')}}</div>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
  name: 'ScanQr',
  data () {
    return {
      error: ''
    }
  },
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    pushTo (nameRoute) {
      this.$router.push({ name: nameRoute })
    },
    onDecode (decodedString) {
      alert(decodedString)
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permission'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
        } else {
          this.error = `ERROR: Camera error (${error.name})`
        }
      }
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100vh;
  padding: 10px 20px;
  border: 1px solid;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.text-scan{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrapper-content{
  height: 65vh;
}
.web-cam{
  width: 100%;
  height: 100%;
  border: 1px solid;
}
.button{
  background-color: #6B4EFF;
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0;
}
</style>
