<template>
  <div class="content">
    <div class="d-flex align-items-center" style="width: 100%">
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <!-- <div style="width: 100%">
        <div class="text-title">
        </div>
      </div> -->
    </div>
    <div class="d-flex justify-content-center">
      <div class="content-detail-feed">
        <h3 class="text-center">
          {{detailFeed.title}}
        </h3>
        <div class="wrapper-image-feed">
          <img :src="detailFeed.urlToImage" alt="" v-if="detailFeed.urlToImage" @error="handlePlaceholderImg">
          <img src="@/assets/images/websiteplanet-dummy-700X400.png" alt="Article Jumbotron" @error="handlePlaceholderImg" v-else>
        </div>
        <div class="created-feed mb-3">
          {{detailFeed.publishedAt}} - by {{detailFeed.author}}
        </div>
        <div v-html="detailFeed.content" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  name: 'DetailFeed',
  data () {
    return {
      feedId: this.$route.params.id,
      detailFeed: {}
    }
  },
  mounted () {
    this.handleAllArticles()
    this.handleDetailFeed()
  },
  methods: {
    ...mapActions(['handleAllArticles']),
    handlePlaceholderImg (e) {
      e.target.src = 'https://dummyimage.com/900x600/000000/ffffff&text=Image+not+found'
    },
    handleDetailFeed () {
      const result = this.getAllFeedOfAllCategory.find(value => {
        return this.feedId == value.id
      })
      const mappingResult = {
        ...result,
        publishedAt: dayjs(result.publishedAt).fromNow()
      }
      this.detailFeed = mappingResult
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters(['getAllFeedOfAllCategory', 'getLoading'])
  },
  watch: {
    getLoading (val) {
      this.handleDetailFeed()
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content{
  height: 90vh;
  overflow-y: auto;
  padding: 10px 20px;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.created-feed{
  font-size: 0.8rem;
  color: #999999;
}
.content-detail-feed{
  background-color: rgb(252, 250, 250);
  width: 70%;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper-image-feed{
  margin-top: 20px;
  width: 450px;
}
.wrapper-image-feed > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .content-detail-feed{
    width: 80%;
  }
  .wrapper-image-feed{
    width: 350px;
  }
}
@media screen and (max-width: 495px) {
  .content-detail-feed{
    width: 95%;
  }
  .wrapper-image-feed{
    width: 250px;
  }
}
</style>
