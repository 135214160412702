<template>
  <div class="container-home">
    <div class="wrapper-top">
      <div>
        <h2>{{$t('welcome_back')}}</h2>
        <h4>{{getDataCustomer.name}}</h4>
        <h6 class="mt-2">{{dateNow}}</h6>
      </div>
      <div class="wrapper-qr-code">
         <!-- @click="pushTo('ScanQr')" -->
        <!-- <img src="@/assets/images/59262-200.png" alt="Qr"> -->
      </div>
    </div>
    <hr style="padding:0 ; margin: 10px 0"/>
    <div class="wrapper-content">
      <div class="wrapper-category-feed">
        <!-- <router-link class="list-category" :to="{name: 'AllCategoryFeed'}">
          <div>All</div>
        </router-link> -->
        <!-- <router-link class="list-category" v-for="(data, i) in categoryFeed" :key="i" :to="{name: 'CategoryFeed', params: {categoryName: data.label.toLowerCase()}}">
          <div>{{data.label}}</div>
        </router-link> -->
      </div>
      <div class="wrapper-feed">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import axios from 'axios'

export default {
  name: 'Home',
  data: () => {
    return {
      categoryFeed: [
        {
          id: 1,
          label: 'Business'
        },
        {
          id: 2,
          label: 'Entertainment'
        },
        {
          id: 3,
          label: 'General'
        },
        {
          id: 4,
          label: 'Health'
        },
        {
          id: 5,
          label: 'Science'
        },
        {
          id: 6,
          label: 'Sports'
        },
        {
          id: 7,
          label: 'Technology'
        }
      ]
    }
  },
  mounted () {
    // this.handleAllFeed()
    this.handleAllArticles()
  },
  methods: {
    ...mapActions(['handleAllFeed', 'handleAllArticles']),
    pushTo (routeName) {
      this.$router.push({ name: routeName })
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer']),
    dateNow () {
      const date = new Date()
      return dayjs(date).format('dddd, DD MMMM YYYY') // '25/01/2019'
    }
  }
}
</script>

<style scoped>
h1, h2, h3, h4, h5, h6{
  margin: 0
}
.wrapper-qr-code{
  width: 70px;
  height: 70px;
}
.wrapper-qr-code > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.wrapper-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}
.wrapper-feed{
  margin-top: 10px;
  padding: 0 23px;
}
.wrapper-category-feed{
  padding-left:23px;
  display: flex;
  width: 100vw;
  overflow-x: auto;
}
a{
  text-decoration: none;
  color: black;
}
.list-category.router-link-active{
  border-radius: 32px;
  color: #6B4EFF;
  background-color: #E7E7FF;
}
a:hover{
  text-decoration: none;
  color: #6B4EFF
}
.list-category{
  padding: 5px 18px;
  margin-right: 10px;
  background-color: #F2F4F5;
  border-radius: 32px;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.container-home{
  position: relative;
  height: 100vh;
}
.title-menu{
  font-size: 0.9rem;
  color: #999999;
}
</style>
