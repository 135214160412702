<template>
  <div :class="[show ? 'container-modal' : 'hidden']" @click="$emit('handleClose', true)">
    <div class="wrapper-modal">
      <div class="d-flex flex-column align-items-center">
        <div class="success">{{title}}</div>
        <div class="continue text-muted">{{message}}</div>
      </div>
      <div class="button mb-2" @click="$emit('handleClose')">
        {{buttonTitle}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalInformation',
  props: {
    title: String,
    message: String,
    buttonTitle: String,
    show: Boolean
  }
}
</script>

<style scoped>
.container-modal{
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}
.hidden{
  display: none;
}
.wrapper-modal{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  height: 50%;
  width: 40%;
  z-index: 999;
  background-color: white;
  border-radius: 10px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.success{
  font-size: 1.7rem;
  font-weight: 500;
  text-align: center;
}
.button{
  background-color: #6B4EFF;
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0;
}
@media screen and (max-width: 768px) {
  .wrapper-modal{
    position: absolute;
    margin: auto;
    height: 80%;
    width: 75%;
    padding: 15px 25px;
  }
}
@media screen and (max-width: 495px) {
  .wrapper-modal{
    position: absolute;
    margin: auto;
    /* height: 24%; */
    max-height: 200px;
    width: 80%;
    padding: 10px 15px;
  }
}
</style>
