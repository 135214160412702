<template>
  <div>
    <div v-if="jumbotronCategory.title && jumbotronCategory.publishedAt">
      <div class="wrapper-content">
        <div class="wrapper-image-jumbotron">
          <img :src="jumbotronCategory.urlToImage" alt="Image">
        </div>
        <div class="title-feed">
          {{jumbotronCategory.title}}
        </div>
        <div class="created-feed">
          {{jumbotronCategory.publishedAt}} - {{jumbotronCategory.author}}
        </div>
        <div class="list-feed mt-4">
          <div class="d-flex mb-4" v-for="data in categoryList" :key="data.id">
            <div class="container-image-list">
              <div class="wrapper-list-image">
                <img :src="data.urlToImage" alt="">
              </div>
            </div>
            <div class="d-flex flex-column justify-content-between container-title-list">
              <div class="title-feed">{{data.title}}</div>
              <div class="description">{{data.content}}</div>
              <div class="created-feed">{{data.publishedAt}} - {{data.author}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="empty-data text-muted">
        {{$t('data_not_found')}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  name: 'CategoryFeed',
  data () {
    return {
      jumbotronCategory: {
        id: Number,
        title: String,
        image: String,
        created: String
      },
      categoryList: [],
      allFeedOfAllCategory: [
        {
          id: 1,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/200/300',
          created: '2h ago . by Hanif',
          category: 'news'
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/250/300',
          created: '2h ago . by Lucky',
          category: 'news'
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/300/300',
          created: '2h ago . by Yogi',
          category: 'news'
        },
        {
          id: 4,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Dicky',
          category: 'news'
        },
        {
          id: 5,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Lucky',
          category: 'promo'
        },
        {
          id: 6,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/300/300',
          created: '2h ago . by Yogi',
          category: 'promo'
        },
        {
          id: 7,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/350',
          created: '2h ago . by Hanif',
          category: 'promo'
        },
        {
          id: 8,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Dicky',
          category: 'promo'
        },
        {
          id: 9,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Lucky',
          category: 'overview'
        },
        {
          id: 10,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/300/300',
          created: '2h ago . by Yogi',
          category: 'overview'
        },
        {
          id: 11,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/350',
          created: '2h ago . by Hanif',
          category: 'overview'
        },
        {
          id: 12,
          title: 'Lorem ipsum dolor, sit amet consectetur adipisicing.',
          image: 'https://placekitten.com/350/300',
          created: '2h ago . by Dicky',
          category: 'overview'
        }
      ],
      categoryName: this.$route.params.categoryName
    }
  },
  mounted () {
    this.handleListCategory()
  },
  methods: {
    handleListCategory () {
      const articles = this.getAllFeedOfAllCategory
      console.log('category feed', this.getAllFeedOfAllCategory)
      const result = articles.filter((value) => {
        return this.categoryName === value.category
      })
      console.log('this.categoryName', this.categoryName)
      console.log('result', result)
      let tempCategory = []
      if (result.length > 0) {
        this.jumbotronCategory = {
          author: result[0].author,
          title: result[0].title,
          urlToImage: result[0].urlToImage,
          publishedAt: dayjs(result[0].publishedAt).fromNow()
        }
        tempCategory = result.slice(1, result.length)
      } else {
        this.jumbotronCategory = {}
        this.categoryList = []
      }

      if (tempCategory.length > 0) {
        this.categoryList = tempCategory
      }
    }
  },
  watch: {
    $route (to) {
      // this.product = []
      this.categoryName = to.params.categoryName
      this.handleListCategory()
    }
  },
  computed: {
    ...mapGetters(['getAllFeedOfAllCategory'])
  }
}
</script>

<style scoped>
.wrapper-content{
  height: 65vh;
  overflow: auto;
}
.title-feed{
  font-size: 01rem;
  font-weight: 600;
  margin: 5px 0;
}
.created-feed{
  font-size: 0.8rem;
  color: #999999;
}
.empty-data{
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
}
.wrapper-image-jumbotron{
  width: 100%;
  height: 200px;
}
.wrapper-image-jumbotron > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrapper-list-image{
  width: 9rem;
  height: 9rem;
}
.wrapper-list-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container-image-list{
  width: 200px;
}

.container-title-list {
  width: 100%;
}
.description{
  display: block;
}
@media screen and (max-width: 768px) {
  .wrapper-image-jumbotron{
    width: 100%;
    height: 200px;
  }
  .description{
    display: none;
  }
  .wrapper-image-jumbotron > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .wrapper-list-image{
    width: 5rem;
    height: 5rem;
  }
  .wrapper-list-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container-image-list{
    width: 120px;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
@media screen and (max-width: 495px) {
}
</style>
