import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from '../views/Auth/Auth'
import LoginPhoneNumber from '../views/Auth/LoginPhoneNumber'
import LoginEmail from '../views/Auth/LoginEmail'
import LoginVerification from '../views/Auth/LoginVerification'
import Register from '../views/Auth/Register'
import RegisterOtp from '../views/Auth/RegisterOtp'
import RegisterVerification from '../views/Auth/RegisterVerification'
import RegisterVerificationEmail from '../views/Auth/RegisterVerificationEmail'
import ForgotPassword from '../views/Auth/ForgotPassword'
import NewPassword from '../views/Auth/NewPassword'

import Main from '../views/Main/Main'
import HomePage from '../views/Main/Home'
import CategoryFeed from '../views/Main/CategoryFeed'
import AllCategoryFeed from '../views/Main/AllCategoryFeed'
import Account from '../views/Main/Account'
import Profile from '../views/Main/Profile'
import ScanQr from '../views/Main/ScanQr'
import MyQr from '../views/Main/MyQr'
import Membership from '../views/Main/Membership'
import Redeem from '../views/Main/Redeem'
import PersonalVoucher from '../views/Main/PersonalVoucher'
import Topup from '../views/Main/Topup'
import DetailFeed from '../views/Main/DetailFeed'
import Notification from '../views/Main/Notification'

import Signup from '../views/Auth/Signup'
import RegisterWithScan from '../views/Auth/RegisterWithScan'

import TestWhatsapp from '../views/Dev/TestWhatsapp'
import TestQRIS from '../views/Dev/TestQRIS'
import TestSwipeEvent from '../views/Dev/TestSwipeEvent'
import PaymentCashlez from '../views/Dev/PaymentCashlez'
import TestQR from '../views/Dev/TestQR'

import Customer from '../views/Customer/Customer'
import HistoryTransaction from '../views/Customer/HistoryTransaction'
import DetailHistoryTransaction from '../views/Customer/DetailHistoryTransaction'

import store from '../store/index'
import Swal from 'sweetalert2'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: 404,
    redirect: '/auth'
  },
  {
    path: '/dev/test-whatsapp',
    name: 'TestWhatsapp',
    component: TestWhatsapp
  },
  {
    path: '/dev/test-qris',
    name: 'TestQRIS',
    component: TestQRIS
  },
  {
    path: '/dev/test-swipe-event',
    name: 'TestSwipeEvent',
    component: TestSwipeEvent
  },
  {
    path: '/dev/payment-cashlez',
    name: 'PaymentCashlez',
    component: PaymentCashlez
  },
  {
    path: '/dev/test-qr',
    name: 'TestQR',
    component: TestQR
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: { requiresVisitor: true },
    redirect: '/auth/login-email',
    children: [
      {
        path: 'login-phone-number',
        name: 'LoginPhoneNumber',
        component: LoginPhoneNumber
      },
      {
        path: 'login-email',
        name: 'LoginEmail',
        component: LoginEmail
      },
      {
        path: 'login-verification',
        name: 'LoginVerification',
        component: LoginVerification
      },
      {
        path: 'signup',
        name: 'Signup',
        component: Signup
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },
      {
        path: 'register-otp',
        name: 'RegisterOtp',
        component: RegisterOtp
      },
      {
        path: 'register-verification',
        name: 'RegisterVerification',
        component: RegisterVerification
      },
      {
        path: 'register-verification-email/:session',
        name: 'RegisterVerificationEmail',
        component: RegisterVerificationEmail
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      }
    ]
  },
  {
    path: '/new-password/:session',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    meta: { requiresAuth: true },
    redirect: '/main/home/all-category-feed',
    children: [
      {
        path: 'home',
        name: 'HomePage',
        component: HomePage,
        children: [
          {
            path: 'all-category-feed',
            name: 'AllCategoryFeed',
            component: AllCategoryFeed
          },
          {
            path: 'category-feed/:categoryName',
            name: 'CategoryFeed',
            component: CategoryFeed
          }
        ]
      },
      {
        path: 'detail-feed/:id',
        name: 'DetailFeed',
        component: DetailFeed
      },
      {
        path: 'account',
        name: 'Account',
        component: Account
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: 'scan-qr',
        name: 'ScanQr',
        component: ScanQr
      },
      {
        path: 'my-qr',
        name: 'MyQr',
        component: MyQr
      },
      {
        path: 'membership',
        name: 'Membership',
        component: Membership
      },
      {
        path: 'redeem',
        name: 'Redeem',
        component: Redeem
      },
      {
        path: 'personal-voucher',
        name: 'PersonalVoucher',
        component: PersonalVoucher
      },
      {
        path: 'topup',
        name: 'Topup',
        component: Topup
      },
      {
        path: 'notification',
        name: 'Notification',
        component: Notification
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('token')) {
      Swal.fire(
        'Must be logged in!!',
        '',
        'error'
      )
      next({
        path: '/auth'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (localStorage.getItem('token')) {
      Swal.fire(
        'You already logged in!!',
        '',
        'warning'
      )
      next({
        path: '/main'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
