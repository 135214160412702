<template>
  <div class="container-home">
    <div class="wrapper-top">
      <div>
        <h2>{{$t('welcome_back')}}</h2>
        <h4>{{getDataCustomer.name}}</h4>
        <h6 class="mt-2">{{dateNow}}</h6>
      </div>
      <div class="wrapper-qr-code">
         <!-- @click="pushTo('ScanQr')" -->
        <!-- <img src="@/assets/images/59262-200.png" alt="Qr"> -->
      </div>
    </div>
    <hr style="padding:0 ; margin: 10px 0"/>
    <div class="wrapper-content">
      <div class="wrapper-feed" v-for="(data, index) in tempData" :key="index">
        <div class="wrapper-image">
          <img src="@/assets/images/Discount Icon.png" alt="">
        </div>
        <div class="desc-discount">
          {{data.desc}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'Notification',
  data () {
    return {
      tempData: [
        {
          desc: 'Segera mendapatkan harga khusus pembelian switzal baby shampoo'
        },
        {
          desc: 'Khusus hari ini ! pembelian minimal Rp 50.0000 akan mendapatkan double points'
        },
        {
          desc: 'Minggu ceria untuk si bunda , pembelian minimal Rp. 100.000 akan mendapatkan potongan spesial'
        },
        {
          desc: 'Pembelian produk johnson & johnson akan mendapatkan promo spesial'
        },
        {
          desc: 'Point anda akan hangus pada tanggal 29 February 2020 sebanyak 3000 points'
        },
        {
          desc: 'Segera kumpulkan point sebanyak banyaknya untuk mendapatkan promo yang menarik'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getDataCustomer']),
    dateNow () {
      const date = new Date()
      return dayjs(date).format('dddd, DD MMMM YYYY') // '25/01/2019'
    }
  }
}
</script>

<style scoped>
h1, h2, h3, h4, h5, h6{
  margin: 0
}
.wrapper-qr-code{
  width: 70px;
  height: 70px;
}
.wrapper-qr-code > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.wrapper-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}
.wrapper-feed{
  margin-top: 10px;
  padding: 0 23px;
  display: flex;
  align-items: center;
}
.wrapper-category-feed{
  padding-left:23px;
  display: flex;
  width: 100vw;
  overflow-x: auto;
}
a{
  text-decoration: none;
  color: black;
}
.list-category.router-link-active{
  border-radius: 32px;
  color: #6B4EFF;
  background-color: #E7E7FF;
}
a:hover{
  text-decoration: none;
  color: #6B4EFF
}
.list-category{
  padding: 5px 18px;
  margin-right: 10px;
  background-color: #F2F4F5;
  border-radius: 32px;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.container-home{
  position: relative;
  height: 100vh;
}
.title-menu{
  font-size: 0.9rem;
  color: #999999;
}
.desc-discount{
  margin-left: 20px;
  font-size: 1rem;
  font-weight: 700;
}
</style>
