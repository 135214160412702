<template>
  <div class="content">
    <div class="d-flex align-items-center mb-4" style="width: 100%">
      <div class="back-button feedback" @click="goBack">
        <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
      </div>
      <div style="width: 100%">
        <div class="text-forgot-password">
          Forgot Password
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div class="wrapper-password">
        <div :class="[passwordPlaceholder ? 'top' : 'center', 'placeholder']" @click="handlePlaceholderPassword">
          Password
        </div>
        <input ref="inputPassword" class="password" type="password" v-model="password" @click="handlePlaceholderPassword" @blur="handlePlaceholderPasswordBlur" @focus="handlePlaceholderPasswordFocus" id="show">
        <div class="wrapper-eye-icon">
          <img src="@/assets/images/closed-eye.png" alt="Close Eye" @click="handleShowPassword" v-if="closeEye">
          <img src="@/assets/images/open-eye.png" alt="Open Eye" v-else  @click="handleShowPassword">
        </div>
        <p class="text-danger" v-if="password.length >=1 && !password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!#%*?&]{8,20}$/)">password should be combination of one uppercase , one lower case, one number and min 8, max 20 char long</p>
      </div>
      <div class="wrapper-confirm-password mt-4">
        <div :class="[confirmPasswordPlaceholder ? 'top' : 'center', 'placeholder']" @click="handlePlaceholderConfirmPassword">
          Confirm Password
        </div>
        <input ref="inputConfirmPassword" class="password" type="password" v-model="confirmPassword" @click="handlePlaceholderConfirmPassword" @blur="handlePlaceholderConfirmPasswordBlur" @focus="handlePlaceholderConfirmPasswordFocus" id="show2">
        <div class="wrapper-eye-icon">
          <img src="@/assets/images/closed-eye.png" alt="Close Eye" @click="handleShowConfirmPassword" v-if="closeEye2">
          <img src="@/assets/images/open-eye.png" alt="Open Eye" v-else  @click="handleShowConfirmPassword">
        </div>
        <p class="text-danger" v-if="confirmPassword && confirmPassword !== password">password not match</p>
      </div>
    </div>
    <div style="margin-top: 100px">
      <button :class="[!password || !confirmPassword || confirmPassword !== password ? 'bg-grey' : 'bg-purple', 'button mt-3']" @click="save" :disabled="!password || !confirmPassword || confirmPassword !== password" >
        Update Password
      </button>
    </div>
  </div>
</template>

<script>
import showPass from '../../mixins/showPassword'
import axios from 'axios'
import { mapMutations } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'NewPassword',
  mixins: [showPass],
  data () {
    return {
      password: '',
      confirmPassword: '',
      closeEye: false,
      closeEye2: false,
      passwordPlaceholder: false,
      confirmPasswordPlaceholder: false
    }
  },
  mounted () {
    console.log('params', this.$route.params.session)
    localStorage.clear()
  },
  created () {
    this.SET_TOKEN(this.$route.params.session)
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    goBack () {
      this.$router.go(-1)
    },
    handleShowPassword () {
      this.showPassword()
      this.closeEye = !this.closeEye
    },
    handlePlaceholderPassword () {
      this.passwordPlaceholder = true
      console.log('input password', this.$refs.inputPassword)
      this.$refs.inputPassword.focus()
    },
    handlePlaceholderPasswordBlur () {
      if (!this.password) {
        this.passwordPlaceholder = false
      }
    },
    handlePlaceholderPasswordFocus () {
      this.passwordPlaceholder = true
    },

    handleShowConfirmPassword () {
      this.showPassword2()
      this.closeEye2 = !this.closeEye2
    },
    handlePlaceholderConfirmPassword () {
      this.confirmPasswordPlaceholder = true
      this.$refs.inputConfirmPassword.focus()
    },
    handlePlaceholderConfirmPasswordBlur () {
      if (!this.confirmPassword) {
        this.confirmPasswordPlaceholder = false
      }
    },
    handlePlaceholderConfirmPasswordFocus () {
      this.confirmPasswordPlaceholder = true
    },
    async save () {
      try {
        const body = { new_password: this.password }
        await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/customer-account/new-password`, body)
        Swal.fire('Success change new password', '', 'success')
        this.$router.push({ name: 'LoginEmail' })
      } catch (error) {
        Swal.fire('Something went wrong', '', 'error')
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50vh; */
  padding: 10px 20px;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.text-forgot-password{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 1.3rem;
  font-weight: 500;
}
.title-input{
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 4px;
}
input.password{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #BBBBBB;
  margin-left: -2px;
  text-justify: auto;
  height: 50px;
  padding-top: 8px;
  padding-left: 7px;
}
input.password:focus{
  outline: 0.3px #6B4EFF solid;
}
.button{
  width: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.bg-grey{
  background-color: #E3E5E6;
  color: #979C9E;
}
.bg-purple{
  background-color: #6B4EFF;
  color: white;
}
.button{
  border: none;
}
.wrapper-password, .wrapper-confirm-password{
  position: relative;
}
.top{
  font-size: 0.75rem;
  left: 7px;
  top: 2px;
  transition: 0.2s;
}
.center{
  left: 7px;
  top: 11px;
  transition: 0.2s;
}
.placeholder{
  position: absolute;
  color: #757575;
}
.wrapper-eye-icon{
  width: 25px;
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
}
.wrapper-eye-icon > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 495px) {
  .wrapper-eye-icon{
    width: 22px;
  }
}
</style>
