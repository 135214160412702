import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '../router/index'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart: [],
    totalItem: null,
    codeChannel: [
      {
        code: '0',
        desc: 'QRIS'
      },
      {
        code: '02',
        desc: 'Mandiri ClikPay'
      },
      {
        code: '03',
        desc: 'KlikBCA'
      },
      {
        code: '04',
        desc: 'DOKU Wallet'
      },
      {
        code: '06',
        desc: 'BRI e-Pay'
      },
      {
        code: '15',
        desc: 'Credit Card Visa/Master/JCB'
      },
      {
        code: '16',
        desc: 'Credit Card Tokenization'
      },
      {
        code: '17',
        desc: 'Recurring Payment'
      },
      {
        code: '18',
        desc: 'BCA KlikPay'
      },
      {
        code: '19',
        desc: 'CIMB Cliks'
      },
      {
        code: '22',
        desc: 'Sinarmas VA'
      },
      {
        code: '23',
        desc: 'MOTO'
      },
      {
        code: '25',
        desc: 'Muamalat Internet Banking'
      },
      {
        code: '26',
        desc: 'Danamon Internet Banking'
      },
      {
        code: '28',
        desc: 'Permata Internet Banking'
      },
      {
        code: '29',
        desc: 'BCA VA'
      },
      {
        code: '31',
        desc: 'Indomaret'
      },
      {
        code: '33',
        desc: 'Danamon VA'
      },
      {
        code: '34',
        desc: 'BRI VA'
      },
      {
        code: '35',
        desc: 'Alfagroup'
      },
      {
        code: '36',
        desc: 'Permata VA'
      },
      {
        code: '37',
        desc: 'Kredivo'
      },
      {
        code: '41',
        desc: 'Mandiri VA'
      },
      {
        code: '42',
        desc: 'QNB VA'
      },
      {
        code: '45',
        desc: 'BNI Yap'
      },
      {
        code: '47',
        desc: 'Arta Jasa VA'
      },
      {
        code: '50',
        desc: 'Link AJA'
      }
    ],
    customer_id: '',
    customer_account_id: '' || localStorage.getItem('customer_account_id'),
    token: '' || localStorage.getItem('token'),
    dataRegistration: {},
    dataCustomer: {},
    customerPoints: 0,
    dataVoucherCustomer: [],
    dataCustomerVoucherPersonal: [],
    dataPaymentMethodBusiness: [],
    allFeedOfAllCategory: [],
    loading: false
  },
  mutations: {
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_ALL_FEED (state, payload) {
      state.allFeedOfAllCategory = payload
    },
    SET_AUTH (state, payload) {
      state.customer_account_id = payload.customer_account_id
      state.token = payload.token
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_CART (state, payload) {
      if (!payload) {
        state.cart = []
        state.totalItem = null
      } else {
        state.cart.push(payload)
        state.totalItem = state.cart.length
      }
    },
    SET_DATA_REGISTRATION (state, payload) {
      state.dataRegistration = payload
    },
    SET_DATA_CUSTOMER (state, payload) {
      state.dataCustomer = payload
      state.customer_id = payload.Customers.length ? payload.Customers[0].id : null
    },
    SET_CUSTOMER_POINTS (state, payload) {
      state.customerPoints = payload
    },
    SET_DATA_VOUCHER_CUSTOMER (state, payload) {
      state.dataVoucherCustomer = payload
    },
    SET_DATA_CUSTOMER_VOUCHER_PERSONAL (state, payload) {
      state.dataCustomerVoucherPersonal = payload
    },
    SET_LOGOUT (state, payload) {
      state.cart = []
      state.totalItem = null
      state.customer_id = ''
      state.customer_account_id = ''
      state.token = ''
      state.dataRegistration = {}
      state.dataCustomer = {}
      state.dataVoucherCustomer = []
      state.dataCustomerVoucherPersonal = []
    },
    SET_PAYMENT_METHOD_BUSINESS (state, payload) {
      state.dataPaymentMethodBusiness = payload
    }
  },
  actions: {
    handleAllFeed (context, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          context.commit('SET_LOADING', true)
          // business, entertainment, general, health, science, sports, technology
          const resBusiness = await axios.get('https://newsapi.org/v2/top-headlines?country=id&sortBy=publishedAt&apiKey=e1d791a4440b465b939e4c088b732ef0&category=business')
          const resEntertainment = await axios.get('https://newsapi.org/v2/top-headlines?country=id&sortBy=publishedAt&apiKey=e1d791a4440b465b939e4c088b732ef0&category=entertainment')
          const resGeneral = await axios.get('https://newsapi.org/v2/top-headlines?country=id&sortBy=publishedAt&apiKey=e1d791a4440b465b939e4c088b732ef0&category=general')
          const resHealth = await axios.get('https://newsapi.org/v2/top-headlines?country=id&sortBy=publishedAt&apiKey=e1d791a4440b465b939e4c088b732ef0&category=health')
          const resScience = await axios.get('https://newsapi.org/v2/top-headlines?country=id&sortBy=publishedAt&apiKey=e1d791a4440b465b939e4c088b732ef0&category=science')
          const resSports = await axios.get('https://newsapi.org/v2/top-headlines?country=id&sortBy=publishedAt&apiKey=e1d791a4440b465b939e4c088b732ef0&category=sports')
          const resTechnology = await axios.get('https://newsapi.org/v2/top-headlines?country=id&sortBy=publishedAt&apiKey=e1d791a4440b465b939e4c088b732ef0&category=technology')

          const dataBusiness = resBusiness.data.articles
          const categoryBusiness = []

          const dataEntertainment = resEntertainment.data.articles
          const categoryEntertainment = []

          const dataGeneral = resGeneral.data.articles
          const categoryGeneral = []

          const dataHealth = resHealth.data.articles
          const categoryHealth = []

          const dataScience = resScience.data.articles
          const categoryScience = []

          const dataSports = resSports.data.articles
          const categorySports = []

          const dataTechnology = resTechnology.data.articles
          const categoryTechnology = []

          dataBusiness.map((value) => {
            const data = { ...value, category: 'business' }
            categoryBusiness.push(data)
          })

          dataEntertainment.map((value) => {
            const data = { ...value, category: 'entertainment' }
            categoryEntertainment.push(data)
          })

          dataGeneral.map((value) => {
            const data = { ...value, category: 'general' }
            categoryGeneral.push(data)
          })

          dataHealth.map((value) => {
            const data = { ...value, category: 'health' }
            categoryHealth.push(data)
          })

          dataScience.map((value) => {
            const data = { ...value, category: 'science' }
            categoryScience.push(data)
          })

          dataSports.map((value) => {
            const data = { ...value, category: 'sports' }
            categorySports.push(data)
          })

          dataTechnology.map((value) => {
            const data = { ...value, category: 'technology' }
            categoryTechnology.push(data)
          })
          const result = [...categoryBusiness, ...categoryEntertainment, ...categoryGeneral, ...categoryHealth, ...categoryScience, ...categorySports, ...categoryTechnology]
          console.log('allFeedOfAllCategory', result)
          // context.commit('SET_ALL_FEED', result)
          resolve(result)
          context.commit('SET_LOADING', false)
        } catch (error) {
          context.commit('SET_LOADING', false)
          reject(error.response.data.message)
        }
      })
    },
    handleAllArticles (context, payload) {
      return new Promise((resolve, reject) => {
        context.commit('SET_LOADING', true)
        axios.get(`${process.env.VUE_APP_API_URL}/api/v1/news-article?active=1&hide=0`)
          .then((res) => {
            const { data } = res.data
            const result = []
            data.map(value => {
              result.push({
                id: value.id,
                author: `${value.Business.name} - ${value.Outlet.name}`,
                title: value.title,
                content: value.body,
                urlToImage: `${process.env.VUE_APP_API_URL}/${value.image}`,
                publishedAt: value.createdAt
              })
            })
            // author, title, urlToImage, publishedAt
            console.log('result', result)
            context.commit('SET_ALL_FEED', result)
            context.commit('SET_LOADING', false)
          })
          .catch(err => {
            context.commit('SET_LOADING', false)
            reject(err.response.data.message)
          })
      })
    },
    customerIsVerified (context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}/api/v1/customer-account/is-verified/${payload.customer_id}`, { is_verified: payload.is_verified })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err.response.data.message)
          })
      })
    },
    sendOtpEmail (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/v1/send-otp-email/verify-otp?email=${payload.email}&verifyCode=${payload.code}`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err.response.data.message)
          })
      })
    },
    customerByJwt (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/v1/customer-account/by-jwt`, {
          headers: { Authorization: 'Bearer ' }
        })
          .then(res => {
            const { data } = res.data
            context.commit('SET_DATA_CUSTOMER', data)
            resolve(data)
          })
          .catch((err) => {
            reject(err.response.data.message)
          })
      })
    },
    login (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/customer/login-loyality-promo`, payload)
          .then(res => {
            const { data } = res.data
            console.log('data login', data)
            localStorage.setItem('customer_account_id', data.payload.customer_account_id)
            localStorage.setItem('token', data.token)
            const payload = {
              id: data.customer_account_id,
              token: data.token
            }
            context.commit('SET_DATA_CUSTOMER', data.dataCustomer)
            context.commit('SET_AUTH', payload)
            resolve(data)
          })
          .catch(err => {
            console.log('err response', err)
            reject(err.response.data.message)
          })
      })
    },
    logout (context, payload) {
      context.commit('SET_LOGOUT')
    },
    register (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/api/v1/auth/customer/register-loyality-promo`, payload)
          .then(res => {
            const { data } = res.data
            context.commit('SET_DATA_REGISTRATION', data.result)
            resolve(data)
          })
          .catch(err => {
            reject(err.response.data.message)
          })
      })
    },
    handleDataCustomer (context, payload) {
      console.log('payload', payload)
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/v1/customer-account/${payload.id}`)
          .then(res => {
            const { data } = res.data
            if (!data.name) { data.name = 'Please insert your name' }
            if (!data.email) { data.email = 'Please insert your email' }
            if (!data.phone_number) { data.phone_number = 'Please insert your phone number' }
            if (data.image) {
              data.image = `${process.env.VUE_APP_API_URL}/${data.image}`
            }
            context.commit('SET_DATA_CUSTOMER', data)
            context.commit('SET_CUSTOMER_POINTS', data.Customers[0].points)

            resolve(data)
          })
          .catch(err => {
            reject(err.response.data.message)
          })
      })
    },
    handleVoucherCustomer (context, payload) {
      const acquisition_type = payload.acquisition_type !== 'all' ? payload.acquisition_type : ''
      console.log('acquisition_type', acquisition_type)
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/v1/customer-voucher-list?acquisition_type=${acquisition_type}`)
          .then(res => {
            const { data } = res.data
            console.log('response voucher customer', data)
            context.commit('SET_DATA_VOUCHER_CUSTOMER', data)
            resolve(data)
          })
          .catch(err => {
            context.commit('SET_DATA_VOUCHER_CUSTOMER', [])
            reject(err.response.data.message)
          })
      })
    },
    handleCustomerVoucherPersonal (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/v1/customer-voucher-personal?customer_account_id=${payload.customer_account_id}`)
          .then(res => {
            const { data } = res.data
            console.log('before handleCustomerVoucherPersonal', data)
            data.map(value => {
              value.Customer_Voucher_List.format_expiration_date = dayjs(value.Customer_Voucher_List.expiration_date || value.expiration_date).format('DD/MM/YYYY HH:mm')
              value.Customer_Voucher_List.format_form_now = dayjs(value.Customer_Voucher_List.expiration_date || value.expiration_date).fromNow()
            })
            console.log('after handleCustomerVoucherPersonal', data)
            context.commit('SET_DATA_CUSTOMER_VOUCHER_PERSONAL', data)
            resolve(data)
          })
          .catch(err => {
            console.log('err', err)
            reject(err.response.data.message)
          })
      })
    },
    handleUpdateImageCustomer (context, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}/api/v1/customer-account/${payload.id}`, payload.data)
          .then(res => {
            const { data } = res.data
            context.dispatch('handleDataCustomer', payload)
            context.commit('SET_DATA_CUSTOMER', data)
            resolve(data)
          })
          .catch(err => {
            reject(err.response.data.message)
          })
      })
    },
    handlePaymentMethodBusiness (context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/api/v1/payment-method/development?businessId=${payload.businessId}`)
          .then(res => {
            const { rows } = res.data.data
            context.commit('SET_PAYMENT_METHOD_BUSINESS', rows)
            resolve(rows)
          })
          .catch(err => {
            reject(err.response.data.message)
          })
      })
    },
    interceptorRequest ({ state }) {
      axios.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${state.token}`
        return config
      }, function (error) {
        return Promise.reject(error)
      })
    },
    interceptorResponse () {
      console.log('interceptorResponse terpanggil')
      axios.interceptors.response.use(
        (response) => {
          console.log('response', response)
          return response
        },
        (err) => {
          console.log('interceptor reasponse err', err.response)
          if (
            err.response.data.statusCode === 401
          ) {
            localStorage.clear()
            window.location.replace('/auth')
          } else if (
            err.response.data.statusCode === 403
          ) {
            Swal.fire('Oppss...', 'session has timed out, please login again', 'warning')
            localStorage.clear()
            window.location.replace('/auth')
          } else if (
            err.response.data.statusCode === 422
          ) {
            localStorage.clear()
            window.location.replace('/auth')
          } else {
            return Promise.reject(err)
          }
        }
      )
    }
  },
  getters: {
    getCart (state) {
      return state.cart
    },
    getTotalItem (state) {
      return state.totalItem
    },
    isLogin (state) {
      return state.token !== null
    },
    getCodeChannel (state) {
      return state.codeChannel
    },
    getDataRegistration (state) {
      return state.dataRegistration
    },
    getDataCustomer (state) {
      return state.dataCustomer
    },
    getDataVoucherCustomer (state) {
      return state.dataVoucherCustomer
    },
    getCustomerAccountId (state) {
      return state.customer_account_id
    },
    getCustomerId (state) {
      return state.customer_id
    },
    getDataCustomerVoucherPersonal (state) {
      return state.dataCustomerVoucherPersonal
    },
    getPaymentMethodBusiness (state) {
      return state.dataPaymentMethodBusiness
    },
    getCustomerPoints (state) {
      return state.customerPoints
    },
    getAllFeedOfAllCategory (state) {
      return state.allFeedOfAllCategory
    },
    getLoading (state) {
      return state.loading
    }
  },
  modules: {
  }
})
