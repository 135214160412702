import en from './assets/i18n/en.json'
import id from './assets/i18n/id.json'

import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages: {
    en, 
    id
  }
})